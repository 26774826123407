export default {
  enum: {
    empty: "",
    transport_characteristics_1: "普货",
    transport_characteristics_2: "配套电池",
    transport_characteristics_4: "内置电池",
    transport_characteristics_8: "纯电池",
    transport_characteristics_16: "液体",
    transport_characteristics_32: "弱磁",
    transport_characteristics_64: "带磁带电",
    transport_characteristics_128: "纺织品",
    transport_characteristics_256: "粉末",
    transport_characteristics_512: "敏感货",

    battery_type_1: "A",
    battery_type_2: "AA",
    battery_type_3: "AAA",
    battery_type_4: "AAAA",
    battery_type_5: "SC",
    battery_type_6: "C",
    battery_type_7: "D",
    battery_type_8: "N",
    battery_type_9: "F",
    battery_type_10: "CR2",
    battery_type_11: "CR5",
    battery_type_12: "CR123A",
    battery_type_13: "非标准电池",
    battery_type_14: "产品特定电池",
    battery_type_15: "锂金属电池",
    battery_type_16: "锂聚合物电池",
    battery_type_17: "锂离子电池",
    battery_type_18: "9V",
    battery_type_19: "6V",
    battery_type_20: "12V",
    battery_type_21: "P76",
    battery_type_22: "LR41",
    battery_type_23: "LR44",
    battery_type_24: "LR63",
    battery_type_25: "LR60",
    battery_type_26: "LR59",
    battery_type_27: "LR66",
    battery_type_28: "LR48",
    battery_type_29: "LR69",
    battery_type_30: "LR57",
    battery_type_31: "LR45",
    battery_type_32: "LR54",
    battery_type_33: "LR55",
    battery_type_34: "LR58",
    battery_type_35: "LR43",
    battery_type_36: "MR-9",
    battery_type_37: "CR2025",
    battery_type_38: "CR2016",
    battery_type_39: "CR2032",
    battery_type_40: "CR2430",
    battery_type_41: "CR2450",
    battery_type_42: "CR2412",
    battery_type_43: "CR3032",
    battery_type_44: "CR2330",
    battery_type_45: "CR2320",
    battery_type_46: "CR2325",

    battery_ingredient_1: "锂离子",
    battery_ingredient_2: "二氧化锰锂",
    battery_ingredient_3: "锂聚合物",
    battery_ingredient_4: "锂金属",
    battery_ingredient_5: "锂",
    battery_ingredient_6: "锂空气",
    battery_ingredient_7: "铅酸",
    battery_ingredient_8: "钴酸锂",
    battery_ingredient_9: "锂镍钴铝",
    battery_ingredient_10: "锂镍锰钴",
    battery_ingredient_11: "磷酸锂",
    battery_ingredient_12: "钛酸锂",
    battery_ingredient_13: "镍铁",
    battery_ingredient_14: "镍锌",
    battery_ingredient_15: "银钙",
    battery_ingredient_16: "银锌",
    battery_ingredient_17: "锌空气",
    battery_ingredient_18: "亚硫酰氯锂",
    battery_ingredient_19: "羟基氧化镍",
    battery_ingredient_20: "氯化锌",
    battery_ingredient_21: "锌碳",
    battery_ingredient_22: "氧化汞",
    battery_ingredient_23: "密封铅酸",
    battery_ingredient_24: "镍氢",
    battery_ingredient_25: "碱性",
    battery_ingredient_26: "镍镉",
    battery_ingredient_27: "氧化银",
    battery_ingredient_28: "铅钙",
    battery_ingredient_29: "铝氧",
    battery_ingredient_30: "铅酸",
    battery_ingredient_31: "锌",
    battery_ingredient_32: "锰",

    product_warehouse_status_0: "待入库",
    product_warehouse_status_1: "已入库",
    product_warehouse_status_2: "待出库",
    product_warehouse_status_3: "已出库",

    //箱状态
    box_status_10: "待入库",
    box_status_20: "已入库",
    box_status_30: "待服务",
    box_status_40: "待出库",
    box_status_50: "待交付/已出库",
    box_status_60: "已交付",
    box_status_190: "取消",

    in_warehouse_status_10: "计划中",
    in_warehouse_status_20: "待支付",
    in_warehouse_status_30: "待入库",
    in_warehouse_status_40: "入库中",
    in_warehouse_status_50: "已完成",
    in_warehouse_status_200: "取消",

    transit_in_warehouse_status_10: "计划中",
    transit_in_warehouse_status_20: "待支付",
    transit_in_warehouse_status_30: "待入库",
    transit_in_warehouse_status_35: "入库异常",
    transit_in_warehouse_status_40: "入库中",
    transit_in_warehouse_status_50: "已完成",
    transit_in_warehouse_status_200: "取消",

    out_plan_status_1: "计划中",
    out_plan_status_2: "待出库",
    out_plan_status_3: "出库中",
    out_plan_status_4: "完成出库",
    out_plan_status_5: "取消出库",

    //仓库类型
    warehouse_type_1: "存储仓",
    warehouse_type_2: "配送仓",
    warehouse_type_4: "物流中心",
    warehouse_type_8: "中转仓",


    in_warehouse_type_1: "存储仓转入",
    in_warehouse_type_2: "配送仓转入",
    in_warehouse_type_3: "其他入库",
    in_warehouse_type_5: "直接入库",
    in_warehouse_type_6: "调拨入库",
    in_warehouse_type_7: "运输入库",
    in_warehouse_type_8: "中转仓转入",

    out_warehouse_type_1: "仓库间调拨",
    out_warehouse_type_2: "转入本地配送仓",
    out_warehouse_type_3: "亚马逊FBA",
    out_warehouse_type_4: "其他地址",
    out_warehouse_type_5: "美客多",
    out_warehouse_type_6: "销毁",

    //入库货物异常情况
    in_warehouse_operation_error_type_1: "接收超时",
    in_warehouse_operation_error_type_2: "错误产品",

    survey_status_1: "请求调查",
    survey_status_2: "调查完成",

    //（代发仓） 入库计划错误产品客户可处理操作
    product_error_dispose_type_11: "漏发",
    product_error_dispose_type_121: "仓库丢失",
    product_error_dispose_type_122: "未成功接收",
    product_error_dispose_type_123: "仓库已找到货",
    product_error_dispose_type_21: "放弃入库并销毁",
    product_error_dispose_type_22: "更换货号",
    product_error_dispose_type_23: "更换标签",
    product_error_dispose_type_12: "请求调查",

    logistics_way_1: "海运",
    logistics_way_2: "空运",
    logistics_way_3: "商业快递",
    logistics_way_4: "铁路",
    logistics_way_5: "邮政",
    logistics_way_6: "陆运",

    delivery_way_10: "DHL",
    delivery_way_20: "UPS",
    delivery_way_30: "USPS",
    delivery_way_40: "FEDEX",
    delivery_way_50: "ESTAFETA",
    delivery_way_60: "PAQUETEXPRESS",
    delivery_way_70: "AMZL",
    delivery_way_80: "MEXICOPOST",
    delivery_way_90: "GRUPOAMPM",
    delivery_way_100: "MERCADOLIBRELOGISTIC",
    delivery_way_110: "JTEXPRESSMX",
    delivery_way_120: "REDPACK",
    delivery_way_130: "IMILE",
    delivery_way_9980: "TRUCK",
    delivery_way_9990: "OTHERS",

    //运输交付方式（确认出库计划）
    // trans_deliver_way_1: "赛易物流",
    // trans_deliver_way_2: "自提",

    delivery_status_1: "待交付",
    delivery_status_2: "待交付-预约延迟",
    delivery_status_3: "等待物流方案",
    delivery_status_4: "完成",

    label_dispose_1: "用户贴标",
    label_dispose_2: "仓库贴标",

    //订单状态
    agent_order_status_5: "待确认",
    agent_order_status_10: "已下单",
    agent_order_status_20: "已取消",
    agent_order_status_30: "处理中",
    agent_order_status_40: "已发运",
    agent_order_status_50: "已送达",
    agent_order_status_60: "取消发货",
    agent_order_status_70: "已取消销毁",
    agent_order_status_80: "已销毁",

    pay_way_10: "余额支付",
    pay_way_20: "账期支付",

    //出库计划的费用 费用类型
    warehouse_fee_10: "仓储费",
    warehouse_fee_20: "卸货费",
    warehouse_fee_30: "标签费",
    warehouse_fee_40: "出库操作费",
    warehouse_fee_50: "订单操作费",
    warehouse_fee_60: "打托费",
    warehouse_fee_70: "包装费",
    warehouse_fee_80: "归集操作费",
    warehouse_fee_90: "调拨操作费",
    warehouse_fee_110: "运输费",
    warehouse_fee_120: "偏远地区附加费",
    warehouse_fee_130: "商业地址派送费",
    warehouse_fee_140: "品类附加费",

    //订单的费用类型
    order_logistics_fee_10: "仓储费",
    order_logistics_fee_20: "卸货费",
    order_logistics_fee_30: "标签费",
    order_logistics_fee_40: "出库操作费",
    order_logistics_fee_50: "订单操作费",
    order_logistics_fee_60: "打托费",
    order_logistics_fee_70: "包装费",
    order_logistics_fee_80: "归集操作费",
    order_logistics_fee_90: "调拨操作费",
    order_logistics_fee_100: "赔付费",
    order_logistics_fee_110: "爽约金",
    order_logistics_fee_120: "退货服务费",
    order_logistics_fee_130: "标准质检费",
    order_logistics_fee_140: "销毁服务费",
    order_logistics_fee_150: "附加功能性质检费",
    order_logistics_fee_160: "人工识别费",
    order_logistics_fee_170: "自提费",
    order_logistics_fee_180: "暂存费",
    order_logistics_fee_190: "合并包装费",
    order_logistics_fee_200: "基础卸货费",
    order_logistics_fee_210: "超规卸货附加费",
    order_logistics_fee_220: "超大件卸货附加费",
    order_logistics_fee_230 :"上架费",
    order_logistics_fee_240 :"装箱费",
    order_logistics_fee_250 :"清点费",
    order_logistics_fee_260 :"缠膜费",
    order_logistics_fee_261 :"缠膜基础费",
    order_logistics_fee_262 :"超量缠膜费",
    order_logistics_fee_500: "运输费",
    order_logistics_fee_501: "DDP关税预报",
    order_logistics_fee_502: "签收签名费",
    order_logistics_fee_503: "达标折扣",
    order_logistics_fee_510: "一般贸易报关费",
    order_logistics_fee_520: "特殊产品附加费",
    order_logistics_fee_530: "品名附加费",
    order_logistics_fee_540: "续页附加费",
    order_logistics_fee_550: "非FBA地址附加费",
    order_logistics_fee_560: "偏远地址附加费",
    order_logistics_fee_570: "超偏远地址附加费",
    order_logistics_fee_580: "远程派送费",
    order_logistics_fee_590: "超规附加费",
    order_logistics_fee_600: "大型包裹附加费",
    order_logistics_fee_610: "尾程派送附加费",
    order_logistics_fee_690: "其他附加费",
    order_logistics_fee_700: "额外附加费",
    order_logistics_fee_1000: "充值",
    order_logistics_fee_1010: "手动退款",

    //调拨至存储仓的计划状态
    allot_plan_status_20: "已计划",
    allot_plan_status_30: "处理中",
    allot_plan_status_40: "已调拨",
    allot_plan_status_50: "已取消",

    //在物流方案中的参考时效中使用
    aging_0: "签收",
    aging_10: "提取",

    //目的地类型
    destination_type_1: "赛易仓库",
    destination_type_2: "亚马逊仓库",
    destination_type_3: "其它地址",
    destination_type_4: "美客多",

    //运输线路
    transport_routes_1: "国际运输",
    transport_routes_2: "本土运输",

    //运输计划状态
    transport_status_10: "计划中",
    transport_status_20: "等待上门揽件",
    transport_status_30: "上门揽件中",
    transport_status_40: "已完成揽件",
    transport_status_50: "操作中心等待收货",
    transport_status_60: "操作中心已收货",
    transport_status_70: "待审核",
    transport_status_80: "等待支付",
    transport_status_90: "已支付",
    transport_status_100: "运输中",
    transport_status_105: "到达赛易仓库",
    transport_status_110: "已送达",
    transport_status_999: "已取消",

    overdraft_status_1: "正常",
    overdraft_status_2: "逾期未付",

    //交易类型
    transaction_type_10: "付款",
    transaction_type_20: "赔偿",
    transaction_type_30: "充值",
    transaction_type_40: "退款",
    transaction_type_50: "达标折扣",

    //业务类型
    business_type_10: "充值",
    business_type_20: "存储仓出库",
    business_type_30: "配送订单",
    business_type_40: "运输计划",
    business_type_50: "仓储赔付",
    business_type_60: "运输赔付",
    business_type_70: "整柜预约入库",
    business_type_80: "存储仓存储",
    business_type_90: "配送仓存储",
    business_type_100: "调拨至存储仓",
    business_type_110: "定制服务",
    business_type_120: "退货处理",
    business_type_130: "存储仓入库",
    business_type_140: "配送仓入库",
    business_type_150: "爽约金",
    business_type_160: "运单",
    business_type_170: "退货计划",
    business_type_180: "手动退款",
    business_type_190: "中转仓入库",
    business_type_200: "中转仓出库",
    business_type_210: "中转仓存储",
    business_type_220: "退货暂存",
    business_type_230: "退货暂存销毁",
    business_type_240: "存储仓换标",
    business_type_250: "中转仓换标",
    business_type_260: "线下服务",

    audit_state_0: "未提交",
    audit_state_1: "审核中",
    audit_state_2: "审核通过",
    audit_state_3: "审核未通过",

    // 付款方式
    recharge_type_10: "转账汇款",
    recharge_type_20: "Payoneer",
    recharge_type_30: "企业支付宝",

    // 充值状态
    recharge_status_10: "待确认",
    recharge_status_20: "成功",
    recharge_status_30: "失败",
    recharge_status_40: "待提交",
    recharge_status_50: "已取消",

    //整柜入库规格
    container_type_1: "20英尺普柜",
    container_type_2: "40英尺普柜",
    container_type_3: "40英尺高柜",
    container_type_4: "45英尺高柜",

    //整柜入库状态
    full_container_in_warehouse_type_10: "已预约",
    full_container_in_warehouse_type_30: "已爽约",
    full_container_in_warehouse_type_31: "开始卸货",
    full_container_in_warehouse_type_40: "卸货完成",

    // 拒绝入账的类型
    reject_in_account_1: "款项被拒收",
    reject_in_account_2: "未收到款项",
    reject_in_account_3: "错误的款项金额",

    transportation_delivery_way_1: "赛易物流",
    transportation_delivery_way_2: "自提",

    //自提选择的方式
    self_mention_type_1: "卡车",
    self_mention_type_2: "快递",

    // 渠道退货商业平台
    return_plan_source_type_1: "亚马逊",
    return_plan_source_type_2: "沃尔玛",
    return_plan_source_type_3: "第三方仓库",
    return_plan_source_type_4: "其它",

    // 退货计划状态详细
    return_plan_record_status_10: "已入库",
    return_plan_record_status_20: "销毁",

    // 退货计划状态
    return_plan_status_10: "已计划",
    return_plan_status_20: "接收中",
    return_plan_status_30: "已完成",
    return_plan_status_90: "已取消",

    // 打单-运单操作状态
    waybill_operation_status_1: "已预报",
    waybill_operation_status_2: "待上网",
    waybill_operation_status_3: "待签收",
    waybill_operation_status_4: "已签收",
    waybill_operation_status_5: "已取消",
    waybill_operation_status_6: "手动取消",
    waybill_operation_status_999: "异常",

    // 退货计划可识别码类型
    return_plan_fnsku_type_1: "可扫描信息",
    return_plan_fnsku_type_2: "人工识别",

    //配送订单物流类型
    agent_order_logistics_type_1: "赛易物流",
    agent_order_logistics_type_2: "第三方面单",
    agent_order_logistics_type_3: "销毁",


    fee_calculation_method_0: "模板计算",
    fee_calculation_method_1: "卡车接口",

    pack_material_type_0: "外包装",
    pack_material_type_1: "合并包装",
    pack_material_type_2: "缠膜包装",

    transit_status_10:"创建中",
    transit_status_20:"预约已提交",
    transit_status_30:"预约成功",
    transit_status_40:"入库中",
    transit_status_50:"部分完成",
    transit_status_60:"超额完成",
    transit_status_70:"完成",
    transit_status_190:"预约取消",
    transit_status_200:"预约失败",

    transportation_method_10:"商业快递",
    transportation_method_20:"集装箱",
    transportation_method_30:"卡车",

    box_type_10:"常规",
    box_type_20:"超规",
    box_type_30:"超大件",

    transit_in_bound_error_customer_type_11:"漏发",
    transit_in_bound_error_customer_type_12:"请求调查",
    transit_in_bound_error_customer_type_121:"仓库丢失",
    transit_in_bound_error_customer_type_122:"未成功接收",
    transit_in_bound_error_customer_type_123:"仓库已找到货",

    transit_out_bound_user_status_10:"已创建",
    transit_out_bound_user_status_20:"仓库处理中",
    transit_out_bound_user_status_30:"部分完成",
    transit_out_bound_user_status_40:"已完成",
    transit_out_bound_user_status_99:"已取消",

    schedule_delivery_type_1:"任意时间",
    schedule_delivery_type_2:"日期",
    schedule_delivery_type_3:"日期和时间",

    transit_delivery_method_1: "赛易物流",
    transit_delivery_method_2: "自提",

    // 预约时间类型
    schedule_time_type_1: "提货时间",
    schedule_time_type_2: "发运时间",
    schedule_time_type_3: "送达时间",

    // 货件状态
    transit_shipment_status_10: "待入库",
    transit_shipment_status_20: "入库中",
    transit_shipment_status_30: "已入库",
    transit_shipment_status_35: "服务中",
    transit_shipment_status_40: "待出库",
    transit_shipment_status_50: "部分出库",
    transit_shipment_status_60: "已出库",
    transit_shipment_status_190: "被拒收",

    // 
    transit_shipment_detail_status_10: "待入库",
    transit_shipment_detail_status_20: "入库取消",
    transit_shipment_detail_status_30: "已入库",
    transit_shipment_detail_status_35: "服务中",
    transit_shipment_detail_status_40: "待出库",
    transit_shipment_detail_status_50: "已出库",

    // 核验类别
    box_check_type_0: "未核验",
    box_check_type_1: "手动核验",
    box_check_type_2: "闸机自动核验",

    sendout_type_1:"自己货物",
    sendout_type_2:"第三方货物",

    inventory_adjustment_type_1: "销毁",
    inventory_adjustment_type_2: "丢失",
    inventory_adjustment_type_3: "移除",

    rts_status_1: "无匹配",
    rts_status_2: "已匹配",
    rts_status_3: "已完成退货",
    rts_status_4: "已销毁",
    rts_status_5: "已取消",

    rts_destroyed_type_1: "用户销毁",
    rts_destroyed_type_2: "仓库销毁",
    rts_destroyed_type_3: "直接销毁",

    label_task_type_1: "箱标",
    label_task_type_2: "产品标",
    label_task_type_3: "箱标和产品标",

    label_task_status_10: "计划中",
    label_task_status_20: "已下单",
    label_task_status_30: "处理中",
    label_task_status_40: "已完成",
    label_task_status_190: "已取消",
    
    //签名签收类型
    signature_type_1: "直接签名",
    signature_type_2: "成人签名",

    //配送仓入库异常 错误产品 选择的处理方式
    in_warehouse_error_for_error_product_customer_type_21:"放弃入库并销毁",
    in_warehouse_error_for_error_product_customer_type_22:"更换箱内信息",

    announce_type_1: "更新日志",
    announce_type_2: "系统公告",

    // 物流节点
    logistics_node_10: "离港",
    logistics_node_20: "到港",
    logistics_node_30: "到仓",

    // 物流账期计算配置
    logistics_accounting_period_calculation_way_1: "即时计算",
    logistics_accounting_period_calculation_way_2: "次月计算",

    logistics_calc_method_0: "重量",
    logistics_calc_method_1: "体积",
  },
};
